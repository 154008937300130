
.rainbow-background {
  background: linear-gradient(40deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  animation: animatedgradient 20s ease alternate infinite;
  height: 100vh;
  background-size: 400% 400%;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.is-size-0 {
  font-size: 4rem;
}