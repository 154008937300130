.chompers {
    width: '100vw' !important ;
    max-height: none !important;
}

.top {
    top: 0 !important;
}

.bottom {
    bottom: 0;
}