

@use "bulma/sass" with (
  $family-primary: "'Quicksand', sans-serif",
  $family-secondary: 'Patua One',
  $control-border-width: 2px,
  $input-shadow: none,
  $title-family: 'Concert One',
  $subtitle-family: 'Bebas Neue',
  $subtitle-weight: '400'
);

// @use "bulma/sass" with (
//   $family-primary: "'Roboto Condensed', sans-serif",
//   $family-secondary: 'Patua One',
//   $control-border-width: 2px,
//   $input-shadow: none,
//   $title-family: 'Roboto Condensed',
//   $subtitle-family: 'Bebas Neue',
//   $subtitle-weight: '400'
// );


  .pretty-border-black {
    border: 2px solid black;
    border-radius: .75rem;
}

.is-max-width-500 {
  max-width: 500px;
}



// Import the Google Font
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');